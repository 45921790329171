
import React, { Component } from "react"
import { connect } from "react-redux"
import * as appActions from "../redux/app"
import * as authActions from "../redux/auth"
import Head from 'next/head'
import SBsimpleModal from './SBsimpleModal'
import FormsModal from './FormsModal'
import Router from 'next/router'
import TopBarProgress from "react-topbar-progress-indicator"
import { getLocale, needFormUpdate, getUserEmail } from "../helpers/stateFunctions"
import { isProd } from "../helpers/utility"
import { TEXTS } from "../helpers/statics"
import Cookies from "js-cookie"
import getConfig from 'next/config'
import firebase from "firebase/app"
import { APP } from "../helpers/statics"
import { user_data } from "../helpers/datalayer"
import { getWebsiteBanners } from "../helpers/rioHelper"
import TermModal from "./TermModal"

TopBarProgress.config({ barColors: { "0": "green", "1.0": "green" }, shadowBlur: 5 })

class SBlayout extends Component {

  constructor(props) {
    super()
    this.state = {
      showCookieInfo: false,
      cboxKvkk1: false,
      cboxKvkk2: false,
      showCheckDepremModal: true,
      showFormModal: true,
      pathname: '',
      popupShow: false,
      popupContent: null,
      asPath: '',
    }
  }

  state = {
    showCookieInfo: false,
    popupShow: false,
  }

  componentDidUpdate(prevProps) {
    if (Router?.router?.asPath !== this.state.asPath) {
      this.setState({
        asPath: Router?.router?.asPath,
      })
    }
  }

  componentDidMount() {
    console.info(APP.version)
    this.checkPopupStatus();
    this.props.fetchWebsiteBanner();
    Router.onRouteChangeStart = (url, leke) => {
      this.props.appValueChanged("showLoading", true)
    }
    Router.onRouteChangeComplete = (url) => {
      this.props.appValueChanged("showLoading", false)
      let user = this.props.user?.user
      if (url.indexOf(this.state.pathname) < 0 && user) {
        let segmentType = user.segment?.type
        let balance = user.cards?.[0]?.balance || ""
        let uid = user.sbuxUserId
        this.setState({ pathname: Router.router.pathname })
        user_data({ segmentType, balance, uid })
      }
    }
    Router.onRouteChangeError = () => { this.props.appValueChanged("showLoading", false) }
  }

  checkPopupStatus() {
    if (!Cookies.get("popupseen3")) {
      this.props.setShowPopup(true)
    }
  }

  changeStateKV(k, v) {
    this.setState({ [k]: v })
    if (k == "showFormModal" && v == false) {
      firebase.auth().signOut().then(function () {
        Router.push("/")
      })
    }
  }

  approveForms() {
    let params = { terms: true, personalconsentform: true, personaldisc: true }
    this.props.updateProfile(
      params,
      (resp) => {
      }
    )
  }

  isCookiePolicyAccepted() {
    if (Cookies.get("cookiepolicyaccepted")) {
      return true
    }
    return false
  }

  showCookieInfo() {
    this.setState({ showCookieInfo: true })
  }

  canApprove() {
    let checkFormVersions = this.props.catalog.checkFormVersions
    if (checkFormVersions.terms == false && (checkFormVersions.personaldisc == false || checkFormVersions.personalconsentform == false)) {
      return this.state.cboxKvkk1 && this.state.cboxKvkk2
    } else if (checkFormVersions.terms == false && (checkFormVersions.personaldisc == true && checkFormVersions.personalconsentform == true)) {
      return this.state.cboxKvkk1
    }
    else if (checkFormVersions.terms == true && (checkFormVersions.personaldisc == false || checkFormVersions.personalconsentform == false)) {
      return this.state.cboxKvkk2
    }
  }

  isDateInRange() {
    const currentDate = new Date(); // Current date and time
    // Define the start and end dates for your range
    const startDate = new Date('2023-12-08T00:00:00'); // 8.12.2023 00:00:00
    const endDate = new Date('2023-12-08T23:59:59');   // 8.12.2023 23:59:59
    // Check if the current date is within the specified range
    return currentDate >= startDate && currentDate <= endDate;
  }

  render() {
    let _state = { ...this.props }
    return (
      <div id={"appLayout"}>
        <Head>
          <script src="https://content.sbuxtr.com/static/mfs-client.min.js"></script>
          {/* <script src={getConfig().publicRuntimeConfig.SB_AUTH_API + "/_next/static/jquery-random.min.js"} crossOrigin="anonymous"></script> */}
          <script src="https://content.sbuxtr.com/static/jquery-3.6.0.min.js" integrity="sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=" crossOrigin="anonymous"></script>
          <link rel="canonical" href={typeof window !== 'undefined' ? `https://www.starbucks.com.tr${this.state.asPath}` : `https://www.starbucks.com.tr${this.props.url}`} />
          <title>Starbucks Türkiye {isProd() ? "" : APP.version}</title>
          <link rel="stylesheet" type="text/css" href="/static/style/dist/styles.css" />
          <link rel="stylesheet" type="text/css" href="https://content.sbuxtr.com/static/animate.min.css" />
          <link rel="stylesheet" type="text/css" href="https://content.sbuxtr.com/static/nprogress.min.css" />
          <link rel="stylesheet" type="text/css" href="https://content.sbuxtr.com/static/react-datetime.css" />
          <link rel="apple-touch-icon" sizes="180x180" href="https://content.sbuxtr.com/static/fav/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="https://content.sbuxtr.com/static/fav/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="https://content.sbuxtr.com/static/fav/favicon-16x16.png" />
          <link rel="mask-icon" href="https://content.sbuxtr.com/static/fav/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />
          {!isProd() && <meta name="robots" content="noindex,nofollow"></meta>}
          {false && _state.user?.user &&
            <script dangerouslySetInnerHTML={{
              __html: `
            SETROWSCRIPTPARAMETERS_2 = { 
              uye_bilgileri: {
                eposta: "${getUserEmail(_state)}"
                }
              };
           `}} />}
        </Head>

        {_state.app.showLoading && <TopBarProgress />}

        {this.props.children}
        {_state.app.apiResponse && _state.app.apiResponse.data && _state.app.apiResponse.data.message && _state.app.apiResponse.data.message[getLocale(_state)] && _state.app.apiResponse.data.code != 50 &&
          <SBsimpleModal titleText={_state.app.apiResponse.data.message[getLocale(_state)]} btn1Text={TEXTS.ok[getLocale(_state)]} onClickBtn1={() => { this.props.appValueChanged("apiResponse", "") }} />}

        {_state.app.showSimpleModal &&
          <SBsimpleModal titleText={_state.app.simpleModalText} btn1Text={TEXTS.ok[getLocale(_state)]} onClickBtn1={() => { this.props.appValueChanged("showSimpleModal", false) }} />}

        {this.state.showCookieInfo &&
          <div className="v3-sb-generic-modal" style={{ display: "block" }}>
            <div className="capsul">
              <div className="head">
                <div className="left">
                  <span className="title">{getLocale(_state) == "en" ? "Your cookie preferences on this website " : "Bu Sitedeki Çerezlerle İlgili Seçimleriniz"}</span>
                </div>
                <div className="right" onClick={() => { this.setState({ showCookieInfo: false }) }}>
                  <img className="close-icon" src="https://content.sbuxtr.com/images/svg/v2-close-modal-icon.svg" />
                </div>
              </div>
              <div className="content">
                <p className="text">
                  {getLocale(_state) == "en" ?
                    "Cookies are essential for the website to function properly. We use cookies to improve your experience, provide and optimize site functionality, collect statistics, and deliver content tailored to your interests. The purpose of collecting cookies is to enable the site to remember your preferences and login details. Click 'Accept' and 'Next' to give your consent to accept cookies and go directly to the site or click on 'More Information' to see detailed descriptions of the types of cookies we store and to decide whether to accept certain cookies while on the website."
                    : "Çerezler bir sitenin düzgün çalışması için önemlidir. Deneyiminizi daha iyi hale getirmek için çerezleri oturum açma ayrıntılarını hatırlamak ve güvenli oturum açmanızı sağlamak; site işlevselliğini en iyi duruma getirmek için istatistik toplamak ve ilgi alanlarınıza göre şekillendirilmiş içerik sunmak için toplarız. Çerezleri Kabul etmek için Kabul Et ve Devam Et’I tıklayarak doğrudan siteye gidin veya çerez türlerinin ayrıntılı tanımlarını görmek için Daha Fazla Bilgi’yi tıklayın ve site üzerindeyken belirli çerezleri kabul edip etmeyeceğinize karar verin."}
                </p>
              </div>
            </div>
          </div>}

        {this.state.showFormModal && needFormUpdate(_state) &&
          <FormsModal _state={_state}
            checkFormVersions={_state.catalog.checkFormVersions}
            cboxKvkk1={this.state.cboxKvkk1}
            cboxKvkk2={this.state.cboxKvkk2}
            changeStateKV={(k, v) => this.changeStateKV(k, v)} approveForms={() => { this.approveForms() }}
            approveDisabled={!this.canApprove()}
          />}

        {_state.app.showPopup && _state.app.popupContent && this.state.asPath !== "/privacy-policy" &&
          <div className="sb-white-dialog-box">
            {_state.app.popupContent?.url ?
              <div className="in-modal modal-just-text" style={{ maxWidth: "100%", width: "700px" }}>
                <img onClick={() => { this.props.setShowPopup(false), Cookies.set("popupseen3", true) }} src="https://content.sbuxtr.com/images/grey-close.svg" style={{ position: 'absolute', right: 5, top: 5, cursor: 'pointer' }} />
                <a href={_state.app.popupContent?.url} target="_blank">
                  <img onClick={() => { this.props.setShowPopup(false), Cookies.set("popupseen3", true) }} src={_state.app.popupContent?.image} alt="popup_content" />
                </a>
              </div> :
              <div className="in-modal modal-just-text" style={{ maxWidth: "100%", width: "700px" }}>
                <img onClick={() => { this.props.setShowPopup(false), Cookies.set("popupseen3", true) }} src="https://content.sbuxtr.com/images/grey-close.svg" style={{ position: 'absolute', right: 5, top: 5, cursor: 'pointer' }} />
                <img onClick={() => { this.props.setShowPopup(false), Cookies.set("popupseen3", true) }} src={_state.app.popupContent?.image} alt="popup_content" />
              </div>
            }
          </div>
        }
        {_state.app.showTermModal &&
          <TermModal url={_state.app.termUrl}
            onClose={() => {
              this.props.showTermModal(false, "")
            }} />
        }
      </div>
    )
  }
}

function mapDispatchToProps(dispatch) {
  return {
    //APP//
    appValueChanged: (k, v) => { dispatch(appActions.appValueChanged(k, v)) },
    showModalWithText: (text) => { dispatch(appActions.showModalWithText(text)) },

    //AUTH//
    updateProfile: (params, callback) => { dispatch(authActions.updateProfile(params)) },

    setShowPopup: (value) => {
      dispatch(appActions.setShowPopup(value));
    },

    fetchWebsiteBanner: () => {
      dispatch(appActions.fetchWebsiteBanner());
    },

    showTermModal: (value, url) => { dispatch(appActions.setShowTermModal(value, url)) },
  }
}

function mapStateToProps(state) {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SBlayout)

